import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import HeatMap from 'react-heatmap-grid';
import {
  Button,
  Card,
  Form,
} from 'react-bootstrap';
import { inputs } from '../actions';
import SimpleTable from './SimpleTable';

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10,
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee',
};

class DropTransitionMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      heatmapData: [],
      configurationName: null,
      renderHelp: false,
      helpText: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
  }

  componentDidMount() {
    if (this.props.transitionMatrix.isLoading) {
      this.props.fetchTransitionMatrices(sessionStorage.getItem('jwtToken'));
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data.transitionMatrixName = this.refs.transitionMatrixName.value;
    data.transitionMatrixValues = this.state.heatmapData;
    this.props.addTransitionMatrix(data, sessionStorage.getItem('jwtToken'));
  }

  onDrop = (files) => {
    this.setState({
      files: files.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })),
    });
    const reader = new FileReader();
    const reactVar = this;
    reader.onload = function () {
      const lines = reader.result.split('\r\n').map((line) => (
        line.split(';').map((item) => parseFloat(item))
      ));
      reactVar.setState({
        heatmapData: lines,
      });
    };
    reader.readAsText(files[0]);
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  deleteTransitionMatrix = (id) => {
    this.props.deleteTransitionMatrix(id, sessionStorage.getItem('jwtToken'));
  }

  renderHelp = (content) => {
    if (this.state.renderHelp) {
      this.setState({
        renderHelp: false,
        helpText: undefined,
      });
    } else {
      this.setState({
        renderHelp: true,
        helpText: content,
      });
    }
  }

  trashcanColumn = {
    accessor: 'delete_matrix',
    Cell: ({ original }) => (
      (
        <i
          className="fa fa-trash"
          style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
          onClick={() => this.deleteTransitionMatrix(original.transition_matrix_id)}
        >
        </i>
      )
    ),
    header: 'Delete Transition Matrix',
    sortable: false,
    width: 45,
  };

  render() {
    const renderTransitionMatrixOverview = !this.props.transitionMatrix.isLoading;
    const { files } = this.state;

    const xLabels = new Array(21).fill(0).map((_, i) => `${i + 1}`);
    const yLabels = new Array(21).fill(0).map((_, i) => `${i + 1}`);

    const transitionMatrixOverview = () => (
      (
        <SimpleTable
          data={this.props.transitionMatrix.data}
          sortColumn='transition_matrix_id'
          sortDescendingOrder={false}
          columns={[
            { accessor: 'transition_matrix_id', Header: 'ID' },
            { accessor: 'name', Header: 'Name' },
            this.trashcan_column,
          ]}
        />
      )
    );

    return (
      <div>
      <div>
        <Card className="mt-4">
          <Card.Header>
            <div className="fa-pull-left">Upload Transition Matrix</div>
            <div>
              <span
                className="question-mark fa-pull-right"
                onClick={() => this.renderHelp({
                  header: 'Upload Transition Matrix',
                  body: 'Text',
                })}>
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop.bind(this)}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
                rejectedFiles,
              }) => {
                let styles = { ...baseStyle };
                styles = isDragActive ? { ...styles, ...activeStyle } : styles;
                styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

                if (rejectedFiles.length > 0) {
                  return (<div>Rejected file {rejectedFiles[0].name}</div>);
                }

                return (
                  <div {...getRootProps()} style={styles} >
                    <input {...getInputProps()} />
                    <div>
                      {isDragAccept ? 'Drop' : 'Drag'} files here...
                    </div>
                    {isDragReject && <div>Please provide CSV files</div>}
                  </div>
                );
              }}
            </Dropzone>

            <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Group controlId="transitionMatrix">
                <Form.Control type="text" ref="transitionMatrixName" placeholder="Transition Matrix Name" />
                <Button fill="true" type="submit">Upload Transition Matrix</Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>

      { this.state.renderHelp && (
        <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
          <div className="overlay-help-card">
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">{this.state.helpText.header}</div>
                <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i>
              </Card.Header>
              <Card.Body>{this.state.helpText.body}</Card.Body>
            </Card>
          </div>
        </div>
      )}

      { this.state.heatmapData.length > 0 && (
        <Card className="mt-4">
          <Card.Header>
            <div className="fa-pull-left">Transition Matrix Preview</div>
            <div>
              <span
                className="question-mark fa-pull-right"
                onClick={() => this.renderHelp({
                  header: 'Transition Matrix Preview',
                  body: 'Text',
                })}>
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            <p>Transition Matrix read from {files[0].name}</p>
            <HeatMap
              xLabels={xLabels}
              yLabels={yLabels}
              data={this.state.heatmapData}
              squares
              onClick={(x, y) => alert(`Clicked ${this.state.heatmapData[y][x] * 100}%`)}
              cellStyle={(background, value, min, max) => ({
                background: `rgb(66, 86, 244, ${1 - (max - value) / (max - min)})`,
                fontSize: '11px',
              })}
              cellRender={ (value) => value && `${Math.round(value * 100, 2)}%` }
            />
          </Card.Body>
        </Card>
      )}

      { renderTransitionMatrixOverview && (
        <Card className="mt-4">
          <Card.Header>
            <div className="fa-pull-left">Transition Matrix Overview</div>
            <div>
              <span
                className="question-mark fa-pull-right"
                onClick={() => this.renderHelp({
                  header: 'Transition Matrix Overview',
                  body: 'Text',
                })}>
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            {transitionMatrixOverview()}
          </Card.Body>
        </Card>
      )}
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    scenarios: state.scenarios,
    selections: state.selections,
    jobs: state.jobs,
    transitionMatrix: state.transitionMatrix,
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    addTransitionMatrix: (data, token) => {
      dispatch(inputs.addTransitionMatrix(data, token));
    },
    deleteTransitionMatrix: (id, token) => {
      dispatch(inputs.deleteTransitionMatrix(id, token));
    },
    fetchTransitionMatrices: (token) => {
      dispatch(inputs.fetchTransitionMatrices(token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(DropTransitionMatrix);
